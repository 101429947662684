/* LoginPage.css */

/* .login-page .container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f4;
}
  
  .login-page .form-container-main {
    max-width: 400px;
    width: 100%;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  } */
  input {
    font-size: 14px !important;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    color: #1C1B1F;
}
  
  .login-page .title {
    text-align: left;
    margin-bottom: 12px;
    font-weight: 600;
    color: #313131;
  }
  
  .login-page .subtitle {
    display: block;
    text-align: left;
    margin-bottom: 24px;
    color: rgb(49 49 49 / 75%);
    font-weight: 400;
}
  
  .login-page .form {
    width: 100%;
  }
  
  .login-page .options {
    display: flex;
    justify-content: space-between;
  }
  
  .login-page .forgot-password {
    float: right;
    color: #FF8682;
    cursor: pointer;
  }
  .text-danger {
    color: #FF8682;
  }
  
  .login-page .submit-button {
    width: 100%;
    background-color: #515DEF;
    border-color: #515DEF;
    height: 40px;
  }
  .signup-page{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-page .register-link{
    cursor: pointer;
    text-align: center;
  }
  .signup-page .register-link{
    cursor: pointer;
    text-align: center;
  }
  button.ant-btn span {
    font-size: 14px;
}