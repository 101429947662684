.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
    text-align: center;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
  
  .trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  