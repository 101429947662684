.logo {
  border-bottom: 1px solid #c7cbfa;
  margin: 0;
  padding: 12px;
  height: 63px;
  background: transparent;
}
  
  .site-layout .site-layout-background {
    background: #fff;
  }
  
  .trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  