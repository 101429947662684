


.dc-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #cecece;
    border-radius: 3px;
}
.dc-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.dc-scroll::-webkit-scrollbar-thumb {
    /* background-color: #a8a8a8; */
    border-radius: 3px;
}
.dc-create-textarea-wraper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}
.dc-create-textarea-wraper::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.dc-create-textarea-wraper::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #a8a8a8;
  border-radius: 10px;
}
.dc-msg-popup {
    /* background: rgb(0 0 0 / 65%); */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dc-msg-popup-inner {
    background: #fff;
    border-radius: 20px;
    width: calc(100% - 30px);
    max-width: 1050px;
    max-height: calc(100% - 60px);
    overflow: auto;
}
.dc-msg-popup-inner.dc-step-01 {
    max-width: 950px;
}
.dc-msg-popup-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;
    min-height: 470px;
    justify-content: center;
}
.version-pop-close-btn {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #D7D7D7;
    background: #f6f6f6;
    cursor: pointer;
    z-index: 1;
    transition: .15s;
    opacity: 1;
}
.dc-msg-step-01 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 36px;
}
.dc-lg-btn {
    border-radius: 30px;
    border: 1px solid #ccc;
    height: 44px;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    color: #747474;
    cursor: pointer;
    transition: .25s;
    min-width: 125px;
}
.dc-lg-btn.dc-bg-gray {
    background: #ECECEC;
}
.dc-lg-btn.dc-bg-gray:hover {
    background: #dfdfdf;
}
.dc-lg-btn.is_active {
    background: #0864EF;
    color: #fff;
}
.dc-lg-btn.is_active svg path {
    fill: #fff;
}
.dc-create-btn .dc-lg-btn.dc-bg-gray {
    background: #4612dd;
    color: #fff;
    border: 1px solid #4612dd;
    transition: .25s;
    width: max-content;

}
.dc-create-btn .dc-lg-btn:hover {
    background: #0a57cb;
    border: 1px solid #2c73ff;
}
.dc-main-title {
    font-size: 21px;
    font-weight: 500;
    display: block;
    margin-bottom: 20px;
}

.dc-create-msg-count {
    padding: 6px 10px;
    font-weight: 600;
    color: #fff;
    background: #0864EF;
    border-radius: 8px;
}
.dc-create-msg-wraper-right {
    width: calc(30% - 20px);
}
.dc-create-textarea-wraper {
    overflow-y: hidden;
    overflow-x: scroll;
}
.dc-create-msg-wraper-right {
    background: #f6f6f6;
    border-radius: 16px;
    border: 1px solid #E6E6E6;
    padding: 30px 20px;
}

.dc-create-textarea {
    min-width: 190px;
    height: 100%;
}
.dc-create-textarea-scroll {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
}
.dc-create-textarea-items {
    background: linear-gradient(180deg, #fcfbff, #fff);
    border: 1px solid #e3dcf5;
    border-radius: 12px;
}
.dc-create-textarea-items textarea {
    border: none;
    background: transparent;
    width: 88%;
    flex-grow: 1;
    min-height: 235px;
    padding: 12px;
    outline: none;
}
.dc-create-textarea-btn {
    display: grid;
    grid-template-columns: 1fr 1fr 24px;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    justify-content: space-between;
}
.dc-create-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    gap: 24px;
}
.dc-msg-preview-textInner {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 0 0 32px 32px;
    overflow: hidden;
}
.dc-msg-preview-wraper {
    background: #232A3A;
    flex-grow: 1;
    border-top: 1px solid #4E4E4E;
    padding: 13px;}
.dc-previwer-textwrap {
    position: relative;
    background: #F2F2F7;
    padding: 8px;
    border-radius: 0 6px 6px 6px;
    font-size: 12px;
}
.dc-msg-content {
    display: block;
    color: #2C2C2E;
}
.dc-msg-time {
    display: block;
    text-align: right;
    font-size: 10px;
    margin-top: 10px;
}

.keywords-wraper span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
}

.keywords-wraper input {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 8px;
    border-radius: 6px;
    font-size: 12px;
}

.keywords-wraper {
    margin-bottom: 24px;
}
.nw-msg-footer {
    background: rgb(70 18 221 / 5%);
    border: 1px solid rgb(70 18 221 / 15%);
    border-radius: 8px;
    flex-grow: 1;
    max-width: 868px;
    padding: 16px;
    align-items: center;
    display: flex;
}
.nw-msg-textIcon {
    align-items: center;
    display: flex;
}
.nw-msg-textIcon>span {
    color: #170f49;
    font-size: 16px;
    font-weight: 600;
}
.nw-msg-dropbox {
    position: relative;
}
.nw-msg-dropIcon {
    display: flex;
    padding: 5px 10px;
}
.nw-msg-dropCotent {
    background: #373d3f;
    border-radius: 5px;
    color: #fff;
    font-size: 11px;
    left: 100%;
    max-width: 350px;
    min-height: 75px;
    min-width: 185px;
    opacity: 0;
    padding: 9px;
    position: absolute;
    top: -15px;
    transform: scale(0);
    transform-origin: top left;
    transition: .15s;
}
.nw-msg-dropCotent:after {
    background: #373d3f;
    content: "";
    height: 14px;
    left: -5px;
    position: absolute;
    top: 20px;
    transform: rotate(45deg);
    width: 14px;
    z-index: 99;
}
.nw-msg-dropbox:hover .nw-msg-dropCotent {
  opacity: 1;
  transform: scale(1);
}

.nw-msg-footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.nw-msg-links {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #4612dd;
  background: rgb(70 18 221 / 7%);
  border: 1px solid rgb(70 18 221 / 50%);
  padding: 6px 10px;
  border-radius: 6px;
  font-weight: 500;
  text-decoration: none;
  transition: .2s;
  font-size: 14px;
  cursor: pointer;
}


.nw-msg-links:hover, .nw-msg-links.is_active {
    background: #4612dd;
    border: 1px solid #4612dd;
    color: #fff;
}

.nw-msg-links:hover .nw-msg-stroke path,
.nw-msg-links.is_active .nw-msg-stroke path {
  stroke: #fff;
}

.nw-msg-links:hover .nw-msg-fill path,
.nw-msg-links.is_active .nw-msg-fill path {
  fill: #fff;
}
.nw-msg-links:hover .nw-msg-stroke-fill path,
.nw-msg-links.is_active .nw-msg-stroke-fill path {
  fill: #fff;
  stroke: #fff;
}
.dc-create-msg-top-chip {
    align-items: center;
    background: rgb(70 18 221 / 7%);
    border: 1px solid #4612dd;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 6px 12px;
    width: calc(100% - 133px);
}
.dc-create-msg-top-chip input {
    background: #0000;
    border: none;
    outline: none;
}
.dc-create-msg-innerMain {
    display: flex
;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px;
    position: relative;
}
.dc-create-leftInnerStart {
    flex-grow: 1;
    width: 80%;
}
.dc-create-msg-leftLast {
    height: 100%;
    /* margin-top: 12px; */
    position: relative;
    width: 115px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #f6f6f6;
    border: 1px dashed #bebebe;
    border-radius: 12px;
}
.dc-add-varient-btn {
    background: #0000;
    border: none;
    color: #4612dd;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    gap: 8px;
    min-height: 239px;
    text-decoration: underline;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}


@media (max-width: 850px) {
    .dc-selectTemplate {
        grid-template-columns: 1fr;
    }
    
    .dc-create-textarea {
        width: 100%;
    }
    .dc-select-temp-header {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 767px) {
    .dc-create-msg-leftLast {
        width: 100%;
    }
    .dc-add-varient-btn {
        min-height: 110px;
    }
    .dc-template-mainWrap .dc-selectTemplate {
        display: block;
        overflow-y: auto;
    }
    .dc-selectLeft {
        position: unset;
        height: auto;
        width: 100%;
    }
    .dc-selectRight {
        padding-left: 0;
        overflow-y: auto;
        height: unset;
    }

}