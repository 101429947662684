
.post-items {
    box-shadow: 0 4px 15px rgb(0 0 0 / 5%);
    max-width: 1070px;
    margin: 16px 0;
    padding: 12px 20px;
    border-radius: 16px;
}
.post-contentTop {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(81 93 239 / 15%);
    padding-bottom: 16px;
}
.post-contentTopLeft {
    display: flex;
    align-items: center;
    gap: 12px;
}
.post-fb-icon {
    width: 30px;
    height: 30px;
    background: #515DEF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.post-ownerName {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.post-ownerWrap {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #313131;
}
.post-ownerWrap {
    font-size: 16px;
    font-weight: 500;
}
.post-btn {
    border: 1px solid rgb(81 93 239 / 25%);
    background: rgb(81 93 239 / 5%);
    padding: 5px 15px;
    border-radius: 6px;
    color: #515DEF;
    font-weight: 500;
    transition: .25s;
}
.post-btn:hover {
    background: rgb(81 93 239 / 100%);
    color: #fff;
}
.post-contentBtm {
    padding-top: 16px;
    display: flex;
    gap: 16px;
}
.post-info-title {
    color: #313131;
    font-weight: 500;
}
.post-readmore-wraper {
    color: #313131;font-size: 12px;
}
.post-readMore-btn {
    color: #515DEF;
    font-weight: 500;
    text-decoration: underline;
    margin-right: 9px;
    cursor: pointer;
}
.post-time {
    opacity: .75;
}
.post-items {
    display: grid;
    grid-template-columns: 60% 12% 12% 10%;
    justify-content: space-between;
}
.post-blueBg {
    background: #515def;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    padding: 10px;
    border-radius: 12px;
    font-size: 12px;
}
.postCount {
    font-weight: 500;
    font-size: 24px;
}
.post-blueBg span {
    min-height: 32px;
}
.post-add-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    color: #515def;
    text-align: center;
    padding: 10px;
    border-radius: 12px;
    font-size: 12px;
    background: #f6f6f6;
    border: 1px dashed rgb(81 93 239 / 50%);
    font-weight: 500;
}


  .post-info-title {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    transition: max-height 0.3s ease;
}

.post-info-title-expanded {
    -webkit-line-clamp: unset;
    overflow: visible;
    max-height: none;
}
.postWrap .title-18{
        font-size: 18px;
    font-weight: 600;
}
.postWrap .search-btn {
    border: none;
    display: inline;
    padding: 0 7px 0 7px;
    border-right: 1px solid #E0E1E3;
    background: transparent;
    margin: 3px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
}
.postWrap .serach-form input {
    border: none;
    padding: 5px;
    color: #8A8C8D;
    border-radius: 6px;
}
.postWrap .serach-form {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 239px;
    border: 1px solid #E0E1E3;
    border-radius: 6px;
}

.postWrap .content-title-box{
    display: flex;
    justify-content: space-between;
}