.group_cont .container{
    max-width:1070px;
    width:100%;
    margin:0 auto;
}
.group_cont input:focus, select:focus {
    outline: none;
}
.group_cont img, .group_cont input,.group_cont select,.group_cont textarea {
    width: 100%;
    max-width: 100%;
    font-family: "DM Sans", sans-serif;
}

.group_cont .group-wraper {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}
.group_cont .group-items {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    padding: 16px;
}
.group_cont .group-name {
    font-size: 13px;
    font-weight: 600;
}
.group_cont .group-footer {
    border-left: 1px solid #c7cbfa;
    justify-content: flex-end;
    padding: 0 0 0 16px;
    align-items: center;
}
.group_cont .group-wraper-inner {
    display: flex;
    gap: 15px;
}
.group_cont .group-name {
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
}
.group_cont .no-bdr {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0 2px;
    cursor: pointer;
    display: flex;
}
.group_cont .no-bdr svg {
    width: 15px;
    height: 15px;
}
.group_cont .group-footer>span {
    font-size: 13px;
    font-weight: 500;
}
.group_cont .group-items input[type="radio"] {
    display: none;
}
.group_cont .group-wraper-right {
    display: flex;
    align-items: start;
    justify-content: space-between;
    background: #efebf8;
    border-radius: 3px;
    margin-bottom: 12px;
    padding: 10px 12px;
    min-height: 70px;
}

.group_cont .group-wraper-right  .group-name {
    padding: 0;
    font-size: 14px;
}

.group_cont .content-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.group_cont .title-18 {
    font-size: 18px;
    font-weight: 600;
}
.group_cont .col_1 .title-18 {
    display: block;
    text-align: center;
    margin: 8px 0 22px;
}
.group_cont .group-member {
    background: rgb(81 93 239 / 10%);
    color: rgb(81 93 239 / 100%);
    font-size: 14px;
    font-weight: 500;
    border-radius: 25px;
    padding: 4px;
    border: 1px solid rgb(81 93 239 / 25%);
    min-width: 60px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.group_cont .group-member.group-member-new {
    padding: 4px 20px;
}
.group_cont .group-member:hover {
    background: rgb(81 93 239 / 100%);
    color: #fff;
}
.group_cont .group-right-footer {
    display: flex;
    align-items: center;
    gap: 6px;
}
.group_cont input[name="radio-group"]:checked + .group-wraper-inner .group-footer svg.fill-only path {
    fill: #fff;
}
.group_cont input[name="radio-group"]:checked + .group-wraper-inner .group-footer svg.stroke-only path {
    stroke: #fff;
    fill: transparent;
}

 .group_cont .message-right input[name="radio-group"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont .message-right input[name="radio-group"]:checked + .group-wraper-right svg path {
    fill: #fff;
}
.group_cont .message-right input[name="gender"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont .message-right input[name="gender"]:checked + .group-wraper-right svg path {
    fill: #fff;
}
.group_cont .message-right input[name="keywords"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont input[name="action"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont input[name="request"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont input[name="interval"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont input[name="lunch"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}
.group_cont input[name="messages"]:checked + .group-wraper-right {
    background: #515def;
    color: #fff;
}

.group_cont .serach-form {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 239px;
    border: 1px solid #E0E1E3;
    border-radius: 6px;
}
.group_cont .serach-form input {
    border: none;
    padding: 5px;
    color: #8A8C8D;
    border-radius: 6px;
}
.group_cont .search-btn {
    border: none;
    display: inline;
    padding: 0 7px 0 7px;
    border-right: 1px solid #E0E1E3;
    background: transparent;
    margin: 3px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
}
.group_cont .search-btn svg {
    width: 18px;
}
.group_cont .shadow-wraper {
    box-shadow: 0 5px 15px rgb(8 15 52 / 7%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
}
.group_cont .content-box {
    max-width: 1150px;
}


