/* LoginPage.css */
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    color: #000 !important; /* Set the desired text color */
    background-color: white !important; /* Set the desired background color */
    background: white !important; /* Set the desired background color */
}
.login-page {
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.form-container-main {
  display: grid;
  grid-template-columns: 45% 50%;
  justify-content: space-between;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  background: #fff;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0 10px 25px rgb(0 0 0 / 7%);
}
.login-image-box {
  background: #FFF8E9;
  border-radius: 20px;
}


  
  .login-page .form {
    width: 100%;
  }
  
  .login-page .options {
    display: flex;
    justify-content: space-between;
  }
  
  .login-page .forgot-password {
    float: right;
    color: #FF8682;
    cursor: pointer;
  }
  
  .login-page .submit-button {
    width: 100%;
    background-color: #515DEF;
    border-color: #515DEF;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
  }
  button.ant-btn.ant-btn-lg.ant-btn-block {
    background-color: #515DEF;
    border-color: #515DEF;
  }
  .signup-page{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-page .register-link{
    cursor: pointer;
    text-align: center;
    font-size: 14px;
  }
  .signup-page .register-link{
    cursor: pointer;
    text-align: center;
  }
  .form-container-main .ant-card.ant-card-bordered {
    padding: 0 !important;
    border: 0;
}

.form-container-main .ant-card-body {
    padding: 0 !important;
}
.form-container-main span.ant-typography-secondary {
  color: #313131;
  font-weight: 500;
}