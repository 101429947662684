.users-title {
    display: flex;
    justify-content: space-between;
    align-items: center
}

aside.ctm-main-sidebar {
    background: #EEEFFD !important;
    border-right: 1px solid #C7CBFA;
}

aside.ctm-main-sidebar ul.ant-menu-dark {
    background: #eeeffd;
    padding: 0 12px;
    margin-top: 20px;
}

aside.ctm-main-sidebar li.ant-menu-item {
    background: #F6F7FE;
    color: #333333;
    margin: 16px 0;
    width: 100%;
    padding: 0px 12px  !important;
    transition: 0s !important;
}

aside.ctm-main-sidebar li.ant-menu-item.ant-menu-item-selected, 
aside.ctm-main-sidebar li.ant-menu-item:hover {
    background: #515DEF !important;
    color: #fff;
}

aside.ctm-main-sidebar li.ant-menu-item a {
    transition: .1s !important;
}

header {
    background: #eeeffd !important;
    border-bottom: 1px solid #c7cbfa;
}

.ctm-main-layout main {
    background: #fff !important;
    margin: 0 !important;
}
.user-profile {
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
}
.user-profile-btn {
    border: 1px solid #c7cbfa;
    padding: 4px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: #fff;
    height: auto;
    box-shadow: none;
    transition: .2s;
}
.user-name-wraper {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.15;
    min-width: 95px;
}
.user-Img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.user-Img * {
    width: 100%;
    height: 100%;
}
.user-name {
    color: #495057;
    font-weight: 400;
    text-align: left;
}
.user-auth {
    color: #929292;
    font-size: 12px !important;
    text-align: left;
}
.user-drop-icon {
    width: 32px;
    height: 32px;
    background: #ECECEC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-profile-btn:hover {
    background: #fff !important;
    box-shadow: 0 3px 7px rgb(0 0 0 / 10%);
}
.trigger.ctm-trigger {
    background: #515DEF;
    margin: 0 24px;
    padding: 9px;
    border-radius: 10px;
}
.ctm-trigger svg {
    color: #fff;
}
aside.ctm-main-sidebar li.ant-menu-item.ant-menu-item-selected svg[att="stroke-only"] path, 
aside.ctm-main-sidebar li.ant-menu-item:hover svg[att="stroke-only"] path {
	stroke: #fff;
}

aside.ctm-main-sidebar li.ant-menu-item.ant-menu-item-selected svg[att="fill-only"] path, 
aside.ctm-main-sidebar li.ant-menu-item:hover svg[att="fill-only"] path {
	fill: #fff;
}

aside.ctm-main-sidebar li.ant-menu-item.ant-menu-item-selected svg[att="stroke-fill-both"] path, 
aside.ctm-main-sidebar li.ant-menu-item:hover svg[att="stroke-fill-both"] path {
	stroke: #fff;
	fill: #fff;
}

/* _______ CSS For DashBoard __________  */
.ctm-dashed-card {
    background: rgb(252 145 122 / 15%);
    border: 1px dashed rgb(252 145 122 / 60%);
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
}
.ctm-dashed-content {
    flex-grow: 1;
}
.ctm-dashed-close-btn {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}
.ctm-dashed-content>span {
    color: #313131;
    font-size: 18px;
    font-weight: 500;
    display: block;
    padding-top: 9px;
}
.ctm-dashed-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
}
.ctm-solid-btn {
    background: #E27140;
    border: none;
    border-radius: 8px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    transition: .2s;
    color: #fff;
    cursor: pointer;
}
.ctm-solid-btn:hover {
    background: #d56332;
}
.fs-24 {
    font-size: 24px;
    font-weight: 600;
    color: #313131;
    margin-bottom: 16px;
}
.dashboard-Leads-wraper {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 12px));
    gap: 16px;
}
.dash-leads-items {
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 12px;
    padding: 20px;
}
.dash-leads-title {
    font-size: 20px;
    font-weight: 600;
}
.dash-leads-iconText {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: #313131;
    font-weight: 400;
}
