
 .ag-msg-popup textarea {
    width: 100%;
}

.ag-msg-popup .loader-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
}
.ag-msg-popup input{
    width: 95%;
}
.ag-msg-popup input:focus,.ag-msg-popup textarea:focus,.ag-msg-popup button:focus {
    outline: none;
}
.ag-msg-popup.ag-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #cecece;
    border-radius: 3px;
}
.ag-msg-popup .ag-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.ag-msg-popup .ag-scroll::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 3px;
}
.ag-msg-popup .ag-create-textarea-wraper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}
.ag-msg-popup .ag-create-textarea-wraper::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.ag-msg-popup .ag-create-textarea-wraper::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #a8a8a8;
  border-radius: 10px;
}
.ag-msg-popup .ag-msg-popup {
    background: rgb(0 0 0 / 65%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ag-msg-popup-inner {
    background: #fff;
    border-radius: 20px;
    max-height: calc(100% - 60px);
    overflow: auto;
}
.ag-msg-popup-inner.ag-step-01 {
    max-width: 950px;
}
.ag-msg-popup-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.version-pop-close-btn {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #D7D7D7;
    background: #f6f6f6;
    cursor: pointer;
    z-index: 1;
    transition: .15s;
    opacity: 1;
}
.ag-msg-step-01 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 36px;
}
.ag-lg-btn {
    border-radius: 30px;
    border: 1px solid #ccc;
    height: 44px;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    color: #747474;
    cursor: pointer;
    transition: .25s;
    min-width: 125px;
}
.ag-lg-btn.ag-bg-gray {
    background: #ECECEC;
}
.ag-lg-btn.ag-bg-gray:hover {
    background: #dfdfdf;
}
.ag-lg-btn.is_active {
    background: #0864EF;
    color: #fff;
}
.ag-lg-btn.is_active svg path {
    fill: #fff;
}
.ag-create-btn .ag-lg-btn.ag-bg-gray {
    background: #0864EF;
    color: #fff;
    border: 1px solid #2c73ff;
    transition: .25s;
    width: max-content;
}
.ag-create-btn .ag-lg-btn:hover {
    background: #0a57cb;
    border: 1px solid #2c73ff;
}
.ag-main-title {
    font-size: 21px;
    font-weight: 500;
    display: block;
    margin-bottom: 20px;
}

.ag-create-msg-count {
    padding: 6px 10px;
    font-weight: 600;
    color: #fff;
    background: #0864EF;
    border-radius: 8px;
}
.ag-create-msg-wraper {
    display: flex;
    gap: 20px;
    align-items: start;
    justify-content: space-between;
}
.ag-create-msg-wraper-left {
    width: 70%;
}
.ag-create-msg-wraper-right {
    width: calc(30% - 20px);
}
.ag-create-textarea-wraper {
    overflow-y: hidden;
    overflow-x: scroll;
}
.ag-create-msg-wraper-right {
    background: #f6f6f6;
    border-radius: 16px;
    border: 1px solid #E6E6E6;
    padding: 30px 20px;
}

.ag-create-textarea {
    min-width: 190px;
    height: 100%;
}
.ag-create-textarea-scroll {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
}
.ag-create-textarea-items {
    background: linear-gradient(180deg, #fcfcfc, #fff);
    border: 1px solid #D4E3FF;
    border-radius: 12px;
}
.ag-create-textarea-items textarea {
    border: none;
    background: transparent;
    width: 100%;
    flex-grow: 1;
    min-height: 235px;
    padding: 12px 3px;
}
.ag-create-textarea-btn {
    display: grid;
    grid-template-columns: 1fr 1fr 24px;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    justify-content: space-between;
}
.ag-message-title{
    border: 1px solid #9ed2ee;
    border-radius: 8px;
    height: 30px;
    padding: 5px
}
.ag-create-btn {
    display: flex;
    align-items: center;
    margin: 20px auto;
    justify-content: end;
}
.ag-msg-preview-textInner {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 0 0 32px 32px;
    overflow: hidden;
}
.ag-msg-preview-wraper {
    background: #232A3A;
    flex-grow: 1;
    border-top: 1px solid #4E4E4E;
    padding: 13px;}
.ag-previwer-textwrap {
    position: relative;
    background: #F2F2F7;
    padding: 8px;
    border-radius: 0 6px 6px 6px;
    font-size: 12px;
}
.ag-msg-content {
    display: block;
    color: #2C2C2E;
}
.ag-msg-time {
    display: block;
    text-align: right;
    font-size: 10px;
    margin-top: 10px;
}

.keywords-wraper span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
}

.keywords-wraper input {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 8px;
    border-radius: 6px;
    font-size: 12px;
}

.keywords-wraper {
    margin-bottom: 24px;
}


.ctm-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #eaeaea;
    border-radius: 3px;
}
.ctm-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.ctm-scroll::-webkit-scrollbar-thumb {
    background-color: #C8C8C8;
    border-radius: 3px;
}
/* .sen-request-popup {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
} */
.sen-request-wraper {
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    /* max-width: 1050px; */
    position: relative;
}
.sen-request-content {
    background: #fff;
    /* width: 100%; */
    max-height: calc(100vh - 60px);
    overflow-y : auto;
    padding: 36px;
}
.request-pop-close {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    cursor: pointer;
    z-index: 1;
    transition: .15s;
    opacity: .75;
}
.request-pop-close:hover {
    opacity: 1;
}
.sen-request-title {
    font-size: 20px;
    font-weight: 500;
    display: block;
    text-align: center;
    margin-bottom: 16px;
    color: #313131;
}
.sen-request-row {
    display: grid;
    grid-template-columns: 33% 26% 36%;
    justify-content: space-between;
}
.sen-group-items {
    
    border-radius: 3px;
    cursor: pointer;
    overflow: hidden;
    display: block;
    border-radius: 8px;
}
.sen-group-wraper-right span {
    color: #515DEF;
    font-weight: 500;
}
.sen-group-items input[type='radio'] {
    display: none;
}
.sen-group-wraper-right {
    min-height: 69px;
    padding: 10px 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F6F7FE;
    border: 1px solid #f0f1f7;
}
.sen-group-items input[type='radio']:checked + .sen-group-wraper-right {
    background: #765def;
}
.sen-group-items input[type='radio']:checked + .sen-group-wraper-right span {
    color: #fff;
}
.sen-group-items  .sen-group-wraper-right:hover {
    background: #765def;
}
.sen-group-items .sen-group-wraper-right:hover span {
    color: #fff;
}
.sen-requests {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
/* .sen-requests .sen-group-items:last-child {
    width: 100%;
} */
.sen-requests .sen-group-items {
    width: calc(50% - 6px);
}
.sen-interval-wraper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.sen-keywords-cards {
    background: #f6f6f6;
    border-radius: 12px;
    border: 1px solid #E6E6E6;
    padding: 24px;
    min-height: 312px;
}
.sen-keywords-box>span {
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
}
.sen-keywords-box input[type='text'] {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
}
.sen-keywords-box input[type='text']:focus {
    outline: none;
}
.sen-interval-wraper .sen-group-wraper-right {
    min-height: 96px;
}
.sen-keywords-tagWrap {
    display: flex;
    flex-wrap: wrap;
}
.keywords-tag-close {
    border: none;
    padding: 0;
    margin-left: 5px;
    background: transparent;
    display: flex;
    align-items: center;
}
.keywords-tag {
    background: #ffffff;
    border-radius: 5px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #c3c3c3;
}
.keywords-tag span {
    line-height: 1;
}
.keywords-tag svg {
    width: 10px;
}
.sen-keywords-box {
    margin-bottom: 24px;
}
.tag-red {
    background: #ffe9e9;
    border: 1px solid #ffb3b3;
    color: #ff0000;
}
.tag-red svg path {
    stroke: #ff0000;
}
.tag-green {
    background: #e8ffe7;
    border: 1px solid #9bdd98;
    color: #07a300;
}
.tag-green svg path {
    stroke: #07a300;
}


@media (max-width: 850px) {
    .sen-request-row {
        grid-template-columns: 100%;
    }
    .sen-group-wraper-right,
    .sen-interval-wraper .sen-group-wraper-right {
        min-height: 40px;
    }
    .sen-request-row {
        gap: 30px;
    }
    .sen-request-content {
        padding: 25px;
    }
}



@media (max-width: 1250px) {
    .ag-create-btn {
        margin-top: 24px;
    }
}

@media (max-width: 1200px) {
    .ag-create-msg-wraper-left {
        width: 100%;
    }
    .ag-create-msg-wraper-right {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .ag-selectTemplate {
        grid-template-columns: 1fr;
    }
    
    .ag-create-textarea {
        width: 100%;
    }
    .ag-select-temp-header {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 767px) {
    .ag-create-msg-leftLast {
        width: 100%;
    }
    .ag-add-varient-btn {
        min-height: 110px;
    }
    .ag-template-mainWrap .ag-selectTemplate {
        display: block;
        overflow-y: auto;
    }
    .ag-selectLeft {
        position: unset;
        height: auto;
        width: 100%;
    }
    .ag-selectRight {
        padding-left: 0;
        overflow-y: auto;
        height: unset;
    }

}