.friends-wraper {
    display: flex;
    align-items: center;
    border: 1px solid rgb(224, 225, 227);
    color: rgb(23, 15, 73);
    padding: 4px 5px;
    border-radius: 30px;
    width: 225px;
    line-height: 1.15;
}

.friends-wraper>img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px;
}

.friends-wraper-name {
    flex-grow: 1;
}

.friends-wraper a {
    display: flex;
}

.frnds_page :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper .ant-table-pagination-right {
    justify-content: center;
}